import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import myImg from "../Assets/avatar2.png";
import { HashLink } from "react-router-hash-link";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineThunderbolt,
  AiOutlinePhone,
} from "react-icons/ai";
import { CiDark, CiLight } from "react-icons/ci";

const themeLocal = localStorage.getItem("theme") ?? "dark";
localStorage.setItem("theme", themeLocal);

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [theme, setTheme] = useState(themeLocal);
  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  function setThemeColor() {
    setTheme(theme === "light" ? "dark" : "light");
    updateExpanded(false);
  }

  useEffect(() => {
    if (theme === "light") {
      document.body.classList.add("light");
      document.querySelector(".home-section").classList.add("light");
      document.querySelectorAll(".navbar-nav .nav-link").forEach((ele) => {
        ele.classList.add("light");
        ele.style.cssText = "color: unset !important;";
      });
      document.querySelector("#hi").classList.add("light");
      document.querySelector(".home-content").classList.add("light");
      document
        .querySelectorAll(".home-about-description > h1")
        .forEach((ele) => {
          ele.classList.add("light");
        });
      document.querySelectorAll(".home-about-social > h1").forEach((ele) => {
        ele.classList.add("light");
      });
      document.querySelectorAll(".home-about-social > p").forEach((ele) => {
        ele.classList.add("light");
      });
      document.querySelector(".home-about-body").classList.add("light");
      document.querySelector(".about-section").classList.add("light");
      document.querySelector(".btn-primary").classList.add("light");
      document.querySelectorAll(".project-heading").forEach((ele) => {
        ele.classList.add("light");
      });
    } else {
      document.body.classList.remove("light");
      document.querySelector(".home-section").classList.remove("light");
      document.querySelectorAll(".navbar-nav .nav-link").forEach((ele) => {
        ele.classList.remove("light");
        ele.style.cssText = "color: white !important;";
      });
      document.querySelector("#hi").classList.remove("light");
      document.querySelector(".home-content").classList.remove("light");
      document
        .querySelectorAll(".home-about-description > h1")
        .forEach((ele) => {
          ele.classList.remove("light");
        });
      document.querySelectorAll(".home-about-social > h1").forEach((ele) => {
        ele.classList.remove("light");
      });
      document.querySelectorAll(".home-about-social > p").forEach((ele) => {
        ele.classList.remove("light");
      });
      document.querySelector(".home-about-body").classList.remove("light");
      document.querySelector(".about-section").classList.remove("light");
      document.querySelector(".btn-primary").classList.remove("light");
      document.querySelectorAll(".project-heading").forEach((ele) => {
        ele.classList.remove("light");
      });
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand className="d-flex">
          <img src={myImg} className="img-fluid logo" alt="avatar" />
          <strong className="main-name heading-name-logo">CunDev</strong>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <HashLink
                smooth
                to="#home"
                className="nav-link"
                onClick={() => {
                  updateExpanded(false);
                }}
              >
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </HashLink>
            </Nav.Item>

            <Nav.Item>
              <HashLink
                smooth
                to="#about"
                className="nav-link"
                onClick={() => {
                  updateExpanded(false);
                }}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </HashLink>
            </Nav.Item>

            {/* <Nav.Item>
              <HashLink to='#project' className="nav-link">
                <AiOutlineFundProjectionScreen 
                  style={{ marginBottom: "2px" }}/>
                  Projects
              </HashLink>
            </Nav.Item> */}

            <Nav.Item>
              <HashLink
                smooth
                to="#experience"
                className="nav-link"
                onClick={() => {
                  updateExpanded(false);
                }}
              >
                <AiOutlineThunderbolt style={{ marginBottom: "2px" }} />
                Experience
              </HashLink>
            </Nav.Item>

            {/* <Nav.Item>
              <HashLink to='#resume' className="nav-link">
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </HashLink>
            </Nav.Item> */}
            <Nav.Item className="fork-btn nav-link">
              <HashLink
                smooth
                to="#contact"
                onClick={() => {
                  updateExpanded(false);
                }}
              >
                <Button className="fork-btn-inner">
                  <AiOutlinePhone
                    style={{ marginBottom: "2px", marginRight: "5px" }}
                  />
                  Contact
                </Button>
              </HashLink>
            </Nav.Item>
            <Nav.Item>
              <span className="nav-link" onClick={setThemeColor}>
                {theme === "light" ? (
                  <CiDark
                    style={{ marginBottom: "2px", fontSize: "x-large" }}
                  />
                ) : (
                  <CiLight
                    style={{ marginBottom: "2px", fontSize: "x-large" }}
                  />
                )}
              </span>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
