import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import Skill from "./components/Skill/Skill";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(()=> {
    if (!load) {   
      const anchor = window.location.hash.slice(1);
      if (anchor) {
          const anchorEl = document.getElementById(anchor);
          if (anchorEl) {
            anchorEl.scrollIntoView({ behavior: 'smooth' });
          }
      }
    }
  },[load])
  

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Home />
        <Skill />
        <Contact/>
      </div>
    </Router>
  );
}

export default App;
