import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Avatar from "../../Assets/avatar.png";
import Button from "react-bootstrap/Button";
import pdf from "../../Assets/../Assets/tran-dinh-thong-cv.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Home() {
  return (
    <>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row className="row-center">
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading" id='hi'>
                Hello World!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> TRAN DINH THONG</strong>
              </h1>
              <div style={{ paddingLeft: 50, paddingBottom: 50, textAlign: "left" }}>
                <Type />
              </div>
              <Button
                variant="primary"
                href={pdf}
                target="_blank"
                className="btn-download-cv"
              >
                <AiOutlineDownload />
                &nbsp;Download My CV
              </Button>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }} className="image-cropper">
              <img
                src={Avatar}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </>
  );
}

export default Home;
