import React from "react";
import { Container } from "react-bootstrap";
import Techstack from "../TechStack";
import Toolstack from "../ToolStack";

function Skill() {
    return (
      <Container fluid className="about-section" id="experience">
        <h1 className="project-heading">
          My <strong className="purple">Experience</strong>
        </h1>
        <Techstack />
        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />
      </Container>
    );
  }
  
  export default Skill;
  