import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import axios from "axios";
import Toast from "react-bootstrap/Toast";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
let buildVersion = Date.now();
const schema = object({
  fullName: string().trim().required(),
  email: string().trim().required().email(),
  phoneNumber: string().trim().required().matches(phoneRegExp),
  emailSubject: string().trim().required(),
  content: string().trim().required(),
});

if (process.env.NODE_ENV === "production") {
  buildVersion = process.env.REACT_APP_BUILD_NUMBER;
}

function Contact() {
  const [showNotify, setShowNotify] = useState({
    status: false,
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = (data) => {
    setLoading(true);
    axios
      .post("https://api.cundev.com/api/auth/send-email", {
        ...data,
        to: "THONG",
      })
      .then((_res) => {
        setShowNotify({
          status: true,
          content: "Send Message Completed!",
        });
        setTimeout(() => {
          setShowNotify({ status: false, content: "" });
          setLoading(false);
        }, 1500);
        setValue("fullName", "");
        setValue("email", "");
        setValue("phoneNumber", "");
        setValue("emailSubject", "");
        setValue("content", "");
      })
      .catch((_err) => {
        setShowNotify({
          status: true,
          content: "Send Message Failed!",
        });
        setTimeout(() => {
          setShowNotify({ status: false, content: "" });
          setLoading(false);
        }, 1500);
        setValue("fullName", "");
        setValue("email", "");
        setValue("phoneNumber", "");
        setValue("emailSubject", "");
        setValue("content", "");
      });
  };
  return (
    <Container
      fluid
      className="home-about-section d-flex align-items-center"
      id="contact"
    >
      <Container>
        <Row className="d-flex justify-content-between align-items-center row-contact">
          <Col md={6} className="home-about-description p-0">
            <Form onSubmit={handleSubmit(onHandleSubmit)} id="formContact">
              <Form.Group as={Row} className="mb-2 me-2 ms-2">
                <Col
                  sm="4"
                  className="mb-2"
                  style={{ paddingRight: 0, paddingBottom: 0 }}
                >
                  <Form.Control
                    disabled={loading}
                    className={errors.fullName ? "error-input" : ""}
                    name="fullName"
                    style={{ fontFamily: "sans-serif" }}
                    type="text"
                    placeholder="Full Name"
                    {...register("fullName")}
                  />
                </Col>
                <Col
                  sm="4"
                  className="mb-2"
                  style={{ paddingRight: 0, paddingBottom: 0 }}
                >
                  <Form.Control
                    disabled={loading}
                    className={errors.email ? "error-input" : ""}
                    name="email"
                    style={{ fontFamily: "sans-serif" }}
                    type="text"
                    placeholder="Email Address"
                    {...register("email")}
                  />
                </Col>
                <Col
                  sm="4"
                  className="mb-2"
                  style={{ paddingRight: 0, paddingBottom: 0 }}
                >
                  <Form.Control
                    disabled={loading}
                    className={errors.phoneNumber ? "error-input" : ""}
                    name="phoneNumber"
                    style={{ fontFamily: "sans-serif" }}
                    type="text"
                    placeholder="Phone Number"
                    {...register("phoneNumber")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-2 me-2 ms-2">
                <Col
                  sm="12"
                  className="mb-2"
                  style={{ paddingRight: 0, paddingBottom: 0 }}
                >
                  <Form.Control
                    disabled={loading}
                    className={errors.emailSubject ? "error-input" : ""}
                    name="emailSubject"
                    style={{ fontFamily: "sans-serif" }}
                    type="text"
                    placeholder="Email Subject"
                    {...register("emailSubject")}
                  />
                </Col>
                <Col sm="12" style={{ paddingRight: 0, paddingBottom: 0 }}>
                  <Form.Control
                    disabled={loading}
                    className={errors.content ? "error-input" : ""}
                    name="content"
                    style={{ height: "150px", fontFamily: "sans-serif" }}
                    as="textarea"
                    placeholder="Your Message"
                    {...register("content")}
                  />
                </Col>
              </Form.Group>
              <Button
                disabled={loading}
                className="mx-auto d-flex"
                form="formContact"
                type="submit"
                variant="primary"
              >
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <BiMailSend fontSize={20} />
                )}
                &nbsp;Send Message
              </Button>
            </Form>
          </Col>
          <Col md={3} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Phone Number:{" "}
              <span
                style={{ fontWeight: "bold", fontFamily: "sans-serif" }}
                className="purple"
              >
                +84 868076100
              </span>
            </p>
            <p>
              Email:{" "}
              <a
                href="mailto:dinhthong.0402@gmail.com"
                style={{ fontWeight: "bold", fontFamily: "sans-serif" }}
                className="purple text-decoration-none"
              >
                dinhthong.0402@gmail.com
              </a>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dinhthong0402"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/cundev.2000"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/thoong.2000"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <p>
              Build Version:{" "}
              <span
                style={{ fontWeight: "bold", fontFamily: "sans-serif" }}
                className="purple"
              >
                #{buildVersion}
              </span>
            </p>
          </Col>
        </Row>
      </Container>
      <Toast
        show={showNotify.status}
        onClose={() => setShowNotify({ status: false, content: "" })}
        className="fixed-top me-3"
        style={{ marginTop: "6rem", left: "unset" }}
      >
        <Toast.Header>
          <strong className="me-auto">Thông Báo</strong>
        </Toast.Header>
        <Toast.Body>{showNotify.content}</Toast.Body>
      </Toast>
    </Container>
  );
}
export default Contact;
